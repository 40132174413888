import Layout from "@/components/Layout/Layout";
import MobileLayout from "@/components/Layout/MobileLayout";
import Loader from "@/components/CustomUiComponents/Loader";
import { useMyCampaigns } from "@/services/campaign";
import useMobile from "@/lib/useMobile";
import CampaignHeader from "@/components/NewCampaignViews/CampaignHeader";
import MobileCampaignHeader from "@/components/NewCampaignViews/MobileCampaignHeader";

export default function Home() {
  const { data } = useMyCampaigns();
  const { isMobile } = useMobile();
  console.log("data", data);
  const campaign =
    data?.find((c) => c.song?.spotifyId && c.artist?.image) ||
    data?.find((c) => true);
  if (data?.length === 0)
    return (
      <Layout title="" page="dashboard">
        <div></div>
      </Layout>
    );
  if (!campaign) {
    return (
      <div id="root-portal">
        <Layout title="" page="dashboard">
          <Loader full={true} />
        </Layout>
      </div>
    );
  }

  if (isMobile === null) {
    return (
      <div id="root-portal">
        <Layout title="" page="dashboard">
          <Loader full={true} />
        </Layout>
      </div>
    );
  } else
    return (
      <div id="root-portal">
        {isMobile ? (
          <MobileLayout title="" page="dashboard" wide>
            <MobileCampaignHeader
              id={campaign.id.toString()}
              setId={() => {}}
              home
            />
          </MobileLayout>
        ) : (
          <div>
            <Layout title="" page="dashboard">
              <CampaignHeader
                id={campaign.id.toString()}
                setId={() => {}}
                home
              />
            </Layout>
          </div>
        )}
      </div>
    );
}
